/* General */

body,
html {
  font-family: "GilroyMedium";
  font-size: 12px;
  color: #fff;
  background-color: #0f0f1b;
}

h1 {
}

p {
}

a {
}

a:visited {
}

.container-home {
  padding: 0;
  margin: 0;
  background-image: url(../images/bg.jpg);
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
}

.container-header {
  padding: 30px 0;
}

.artika-logo {
  width: 178px;
}

.home-menu {
  width: 33px;
}

.container-s1 {
  padding: 50px 0;
}

.container-s1 h1 {
  padding-top: 18px;
}

.container-s1 h1 .p-1 {
  color: #fff;
  font-family: "GilroyMedium";
  font-size: 41px;
  padding: 0;
  margin: 0;
  line-height: 10px;
}

.container-s1 h1 .p-2 {
  color: #c3c36c;
  font-family: "GilroyExtraBold";
  font-size: 54px;
  padding: 0;
  margin: 0;
}

.btn-tienda {
  color: #fff;
  font-size: 29px;
  font-family: "GilroySemiBold";
  width: 514px;
  height: 100px;
  text-align: center;
  background: linear-gradient(
    0deg,
    rgba(171, 171, 93, 1) 0%,
    rgba(195, 195, 108, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  line-height: 32px;
  border: 3px solid #c3c36c;
}

.btn-tienda:hover {
  text-decoration: none;
  color: #fff;
}

.container-s2 {
  padding: 0;
  margin: 0;
  background-image: url(../images/gallery.png);
  min-height: 580px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.container-s2 .row {
  margin: 0;
}

.container-btn-tienda {
  display: flex;
  align-items: center;
  justify-content: right;
}

.container-btn-gallery {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-top: 360px;
  padding-right: 0;
}

.btn-gallery {
  color: #373a81;
  font-size: 29px;
  font-family: "GilroySemiBold";
  width: 514px;
  height: 100px;
  text-align: center;
  background: linear-gradient(
    0deg,
    rgba(229, 231, 235, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  line-height: 32px;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 6px 10px 10px rgba(0, 0, 0, 0.2); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 6px 10px 10px rgba(0, 0, 0, 0.2); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 6px 10px 10px rgba(0, 0, 0, 0.2);
}

.btn-gallery:hover {
  color: #373a81;
  text-decoration: none;
}

.btn-gallery img {
  padding-right: 30px;
}

.container-s3 {
  padding: 60px 0 40px 0;
}

.container-s3 .row {
  margin-right: 0px;
  margin-left: 0px;
}

.container-s3 p {
  color: #fff;
  text-align: center;
  font-family: "GilroyMedium";
  font-size: 26px;
}

.container-s4 {
  padding: 60px 0 40px 0;
  background-color: #fff;
}

.container-s4 .row {
  margin: 0;
}

.container-s4 p {
  color: #2c344c;
  text-align: center;
  font-family: "GilroyMedium";
  font-size: 26px;
}

.container-s4 p span {
  font-family: "GilroyBold";
  font-size: 26px;
}

.container-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.box-menu {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  border: 2px solid #000;
  padding: 60px 40px 20px 16px;
  position: relative;
  border: 2px solid #fff;
  -webkit-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
}

.container-menu ul {
  color: #373a81;
}

.container-menu ul li {
  color: #373a81;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
}

.enabled-false {
  opacity: 0.5;
}

.container-menu ul li a {
  color: #373a81;
}

.container-menu ul li a:hover {
  color: #373a81;
}

.container-menu ul li a span {
  font-size: 16px;
}

.menu-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #373a81;
  padding: 8px;
  border-radius: 16px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  -webkit-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
}

.menu-close-btn img {
  width: 16px;
}

.container-btn-tienda-1 {
  display: none;
  align-items: center;
  justify-content: center;
}

.container-cargador {
  position: fixed;
  background-color: #fcfcff;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loader-text {
  color: #373a81;
  text-align: center;
  font-size: 24px;
  margin-bottom: 0px;
}

.loader-text img {
  width: 450px;
  height: auto;
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #000;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.loader::after {
  animation-delay: 1s;
}

.prev {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 35px;
  -webkit-box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
}

.prev:hover {
  text-decoration: none;
  color: #2c344c;
}

.prev img {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  margin-right: 8px;
}

.prev span {
  color: #29337b;
  font-size: 20px;
}

@keyframes changescale {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

.next {
  position: fixed;
  bottom: 100px;
  left: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 35px;
  -webkit-box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  animation-duration: 1s;
  animation-name: changescale;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.next:hover {
  text-decoration: none;
  color: #2c344c;
}

.next img {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  margin-right: 8px;
}

.next span {
  color: #29337b;
  font-size: 20px;
}

.live-chat {
  position: fixed;
  bottom: 40px;
  right: 40px;
  border-radius: 50px;
  border: 2px solid #fff;
  background: linear-gradient(
    5deg,
    rgba(230, 231, 235, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 10px 26px 10px 20px;
  -webkit-box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.live-chat:hover {
  text-decoration: none;
}

.live-chat .icons-persons {
  margin-right: 16px;
}

.live-chat .icons-persons img {
  height: 50px;
  width: auto;
  overflow: hidden;
  -webkit-box-shadow: 0px 3px 4px 4px rgba(0, 0, 0, 0.1); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 4px 4px rgba(0, 0, 0, 0.1); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  margin-right: -12px;
}

.live-chat .icons-persons img:last-child {
  margin-right: 0px;
}

.live-chat span {
  color: #373a81;
  text-align: center;
  font-size: 22px;
  line-height: 22px;
  text-align: left;
}

.libro-ar {
  display: none;
  position: fixed;
  bottom: 130px;
  right: 40px;
  background: linear-gradient(
    5deg,
    rgba(230, 231, 235, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 120px;
  padding: 12px;
  -webkit-box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid #fff;
}

.libro-ar-btn {
  position: fixed;
  bottom: 40px;
  right: 40px;
  border-radius: 50px;
  border: 2px solid #313773;
  background: #44478a;
  padding: 13px 26px 13px 20px;
  -webkit-box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.libro-ar-btn:hover {
  text-decoration: none;
}

.look-ficha-btn {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 270px;
  margin-left: -135px;
  margin-top: -120px;
  border-radius: 50px;
  border: 2px solid #fff;
  background: linear-gradient(
    5deg,
    rgba(230, 231, 235, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 10px 26px 10px 20px;
  -webkit-box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1);
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.look-ficha-btn:hover {
  text-decoration: none;
}

.look-ficha-btn span {
  color: #373a81;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
}

#modal-video {
  position: fixed;
  z-index: 9999999999999999999;
  text-align: center;
  display: none;
}

.closeVideoModal {
  position: absolute;
  top: 40px;
  right: 40px;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.container-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.box-modal {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  border: 2px solid #000;
  padding: 60px 40px 20px 40px;
  position: relative;
  border: 2px solid #fff;
  -webkit-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.box-modal h2 {
  font-size: 28px;
  color: #373a81;
  text-align: center;
  margin-bottom: 40px;
  padding: 0 10px;
}

.box-modal-container-rotation-screen {
  display: flex;
  margin-bottom: 16px;
  padding: 0 20px;
}

.box-modal-container-rotation-screen img {
  width: 58px;
}

.box-modal-container-rotation-screen span {
  color: #373a81;
  font-size: 13px;
  margin-left: 10px;
}

.box-modal-container-items {
  display: flex;
  align-items: flex-start;
  justify-content: top;
  flex-direction: row;
}

.box-modal-item {
  text-align: center;
  max-width: 140px;
  margin: 0 10px;
}

.box-modal-item img {
  height: 60px;
  margin-bottom: 20px;
}

.box-modal-item p {
  font-size: 16px;
  color: #373a81;
  line-height: 18px;
}

.box-modal-item .img-btn-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.box-modal-item .img-btn-mobile img {
  height: 30px;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #373a81;
  padding: 8px;
  border-radius: 16px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  -webkit-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
}

.modal-close-btn img {
  width: 16px;
}

.menu-hamburger {
  position: fixed;
  top: 40px;
  right: 40px;
}

.menu-audio {
  position: fixed;
  top: 40px;
  right: 100px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.menu-audio img {
  height: 27px;
}

.menu-artika {
  position: fixed;
  top: 40px;
  left: 40px;
}

.container-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.box-form {
  background-color: #212132;
  padding: 60px 30px 30px 30px;
  position: relative;
  border: 2px solid #c3c36c;
  max-width: 480px;
  -webkit-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
  background-image: url(../images/form-img.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 110px auto;
}

.box-form h3 {
  font-size: 1.75rem;
  line-height: 22px;
}

.box-form h4 {
  font-size: 1.75rem;
  font-family: "GilroyBold";
  color: #c3c36c;
  font-style: italic;
}

.box-form-container {
  padding: 0 30px;
}

.box-form-container p {
  font-size: 14px;
  line-height: 15px;
}

.response-message {
  font-size: 14px;
  line-height: 15px;
  margin-top: 10px;
  color: #c3c36c;
  max-width: 278px;
}

.box-form-container .tycos {
  font-size: 9px;
  line-height: 9px;
  margin-top: 20px;
}

.box-form-container .tycos a {
  text-decoration: underline;
  color: #fff;
}

.box-form-container select {
  width: 120px;
  margin-right: 6px;
}

.tycos-cb {
  margin-top: 20px;
}

.tycos-cb label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
  font-size: 14px;
  line-height: 15px;
}

.tycos-cb input {
  width: 13px;
  height: 13px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -3px;
  overflow: hidden;
  margin-right: 8px;
}

.tycos-cb a {
  color: #fff;
  text-decoration: underline;
}

.form-field {
  display: flex;
  margin-bottom: 10px;
}

.form-field label {
  margin-top: 6px;
  margin-right: 10px;
  font-size: 14px;
  line-height: 15px;
}

.form-field input {
  flex: 2;
  background-color: #6a6c87;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  color: #fff;
}

.no-outline:focus {
  outline: none;
}

.form-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #6a6c87;
  padding: 8px;
  border-radius: 16px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  -webkit-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
}

.form-send-btn {
  background: linear-gradient(
    0deg,
    rgba(229, 231, 235, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 13px;
  padding: 10px 60px;
  color: #1d1d1d;
  border: 2px solid #fff;
  font-size: 14px;
  -webkit-box-shadow: 0px 6px 10px 10px rgba(0, 0, 0, 0.2); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 6px 10px 10px rgba(0, 0, 0, 0.2); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 6px 10px 10px rgba(0, 0, 0, 0.2);
  margin-top: 16px;
  display: inline-block;
}

.form-send-btn:hover {
  text-decoration: none;
  color: #1d1d1d;
}

.form-close-btn img {
  width: 16px;
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 992px) {
  .container-s1 h1 .p-1 {
    font-size: 37px;
  }
  .container-s1 h1 .p-2 {
    font-size: 48px;
  }
  .container-s1 .btn-tienda {
    font-size: 29px;
  }
  .btn-gallery {
    font-size: 24px;
  }
  .btn-tienda {
    font-size: 24px;
  }
  .container-s3 p {
    font-size: 22px;
  }
  .container-s4 p {
    font-size: 22px;
    padding: 0 30px;
  }
}

@media only screen and (max-width: 844px) {
  .menu-artika {
    left: 20px;
    top: 20px;
  }
  .artika-logo {
    width: 148px;
  }
  .menu-hamburger {
    top: 20px;
    right: 20px;
  }
  .menu-audio {
    top: 20px;
    right: 70px;
  }
  .closeVideoModal {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .live-chat {
    right: 0;
    bottom: 20px;
    border-radius: 50px 0px 0px 50px;
    padding-right: 10px;
  }
  .live-chat img {
    height: 35px;
    margin-right: 0px;
  }
  .live-chat span {
    font-size: 14px;
    line-height: 14px;
  }
  .libro-ar-btn {
    right: 0;
    bottom: 85px;
    border-radius: 50px 0px 0px 50px;
    padding-right: 10px;
  }
  .libro-ar-btn span {
    font-size: 14px;
    line-height: 14px;
  }
  .libro-ar {
    bottom: 80px;
    right: 12px;
  }
  .libro-ar img {
    width: 80px;
  }
  .container-menu ul li {
    font-size: 20px;
  }
  .box-modal {
    padding-left: 10px;
    padding-right: 10px;
  }
  .box-modal h2 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .box-modal-item img {
    height: 40px;
  }
  .box-modal-item p {
    font-size: 13px;
    line-height: 14px;
  }
  .box-modal-item {
    max-width: 120px;
  }
  .box-modal-item .img-btn-mobile img {
    margin-bottom: 13px;
  }
  .loader-text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .container-s1 {
    padding: 25px 0;
  }
  .container-btn-tienda {
    display: none;
  }
  .container-s2 {
    min-height: 420px;
  }
  .container-btn-gallery {
    padding-top: 30px;
    justify-content: center;
    padding-left: 0;
  }
  .container-btn-tienda-1 {
    display: flex;
    padding: 0px;
    margin-bottom: 30px;
  }
  .btn-gallery {
    width: 100%;
  }
  .menu-artika {
    left: 20px;
    top: 20px;
  }
  .artika-logo {
    width: 148px;
  }
}

@media only screen and (max-width: 576px) {
  .container-s3 p {
    padding: 0 30px;
  }
  .container-s2 {
    min-height: 320px;
  }
  .container-s2 .col {
    padding: 0 15px;
  }
  .container-s2 .container {
    margin: 0;
    padding: 0;
  }
  .container-s2 .container .col {
    padding: 0;
    padding-top: 30px;
  }
  .container-s3 p {
    font-size: 19px;
  }
  .container-s4 p {
    font-size: 19px;
  }
  .container-s4 p span {
    font-size: 19px;
  }
  .menu-artika {
    left: 20px;
    top: 20px;
  }
  .artika-logo {
    width: 148px;
  }
  .menu-hamburger {
    top: 20px;
    right: 20px;
  }
  .menu-audio {
    top: 20px;
    right: 70px;
  }
  .container-btn-tienda-1 {
    padding: 0px;
  }
  .box-modal-item {
    max-width: 94px;
  }
  .box-modal {
    padding-left: 0px;
    padding-right: 0px;
  }
  .box-form {
    max-width: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }
  .box-form-container {
    padding: 0;
  }
  .loader-text {
    font-size: 16px;
  }
  .loader-text {
    padding: 0 20px;
  }
  .loader-text img {
    width: 100%;
    height: auto;
  }
  .live-chat {
    border-radius: 50px 0 0 50px;
  }
  .live-chat .icons-persons img {
    width: 28px;
    height: 28px;
  }
  .libro-ar-btn {
    border-radius: 50px 0 0 50px;
  }
  .prev {
    padding: 7px;
    padding-right: 13px;
  }
  .next {
    padding: 7px;
    padding-right: 13px;
    bottom: 80px;
  }
  .next img {
    width: 28px;
    height: 28px;
    border-radius: 15px;
  }
  .prev img {
    width: 28px;
    height: 28px;
    border-radius: 15px;
  }
  .next span {
    font-size: 16px;
  }
  .prev span {
    font-size: 16px;
  }
}

@media only screen and (max-height: 390px) {
  .box-form {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding: 60px 30px 30px 30px;
    width: 100%;
    max-width: 100%;
  }
  .box-menu {
    overflow: hidden;
    overflow-y: scroll;
    padding-top: 25px;
    padding-bottom: 10px;
  }
  .container-menu ul li {
    font-size: 18px;
    line-height: 20px;
  }
  .box-form {
    padding-top: 30px;
  }
  .box-form h3 {
    font-size: 1.5rem;
    line-height: 20px;
  }
  .box-form h4 {
    font-size: 1.5rem;
  }
  .live-chat {
    border-radius: 50px 0 0 50px;
  }
  .live-chat .icons-persons img {
    width: 28px;
    height: 28px;
  }
  .prev {
    padding: 7px;
    padding-right: 13px;
  }
  .next {
    padding: 7px;
    padding-right: 13px;
    bottom: 80px;
  }
  .next img {
    width: 28px;
    height: 28px;
    border-radius: 15px;
  }
  .prev img {
    width: 28px;
    height: 28px;
    border-radius: 15px;
  }
  .next span {
    font-size: 16px;
  }
  .prev span {
    font-size: 16px;
  }
}

@media (orientation: landscape) {
  /* .box-modal-container-rotation-screen {
    display: none !important;
  } */
}
