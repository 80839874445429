/* @font-face {
    font-family: 'AvertaforTBWA-Bold';
    src: url('../fonts/AvertaforTBWA-Bold.eot');
    src: url('../fonts/AvertaforTBWA-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/AvertaforTBWA-Bold.svg#AvertaforTBWA-Bold') format('svg'),
         url('../fonts/AvertaforTBWA-Bold.ttf') format('truetype'),
         url('../fonts/AvertaforTBWA-Bold.woff') format('woff'),
         url('../fonts/AvertaforTBWA-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
} */

@font-face {
  font-family: "GilroyMedium";
  src: url("../fonts/GilroyMedium.eot");
  src: url("../fonts/GilroyMedium.eot") format("embedded-opentype"),
    url("../fonts/GilroyMedium.woff2") format("woff2"),
    url("../fonts/GilroyMedium.woff") format("woff"),
    url("../fonts/GilroyMedium.ttf") format("truetype"),
    url("../fonts/GilroyMedium.svg#GilroyMedium") format("svg");
}

@font-face {
  font-family: "GilroyExtrabold";
  src: url("../fonts/GilroyExtrabold.eot");
  src: url("../fonts/GilroyExtrabold.eot") format("embedded-opentype"),
    url("../fonts/GilroyExtrabold.woff2") format("woff2"),
    url("../fonts/GilroyExtrabold.woff") format("woff"),
    url("../fonts/GilroyExtrabold.ttf") format("truetype"),
    url("../fonts/GilroyExtrabold.svg#GilroyExtrabold") format("svg");
}

@font-face {
  font-family: "GilroySemibold";
  src: url("../fonts/GilroySemibold.eot");
  src: url("../fonts/GilroySemibold.eot") format("embedded-opentype"),
    url("../fonts/GilroySemibold.woff2") format("woff2"),
    url("../fonts/GilroySemibold.woff") format("woff"),
    url("../fonts/GilroySemibold.ttf") format("truetype"),
    url("../fonts/GilroySemibold.svg#GilroySemibold") format("svg");
}

@font-face {
  font-family: "GilroyBold";
  src: url("../fonts/GilroyBold.eot");
  src: url("../fonts/GilroyBold.eot") format("embedded-opentype"),
    url("../fonts/GilroyBold.woff2") format("woff2"),
    url("../fonts/GilroyBold.woff") format("woff"),
    url("../fonts/GilroyBold.ttf") format("truetype"),
    url("../fonts/GilroyBold.svg#GilroyBold") format("svg");
}
