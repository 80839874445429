.container-ficha {
  /* background-color: #fff; */
  padding-bottom: 15px;
  background-image: url(../images/frida-ficha/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
}

.container-ficha p {
  font-size: 16px;
}

.container-ficha h3 {
  font-size: 24px;
  font-family: "GilroyBold";
}

.container-ficha h4 {
  font-size: 22px;
  font-family: "GilroySemiBold";
}

.container-ficha p:last-child {
  margin-bottom: 0;
}

.container-ficha .text-green {
  color: #c3c36c;
  font-family: "GilroySemibold";
}

.container-fluid-ficha {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  padding-right: 0px !important;
  padding-left: 0px !important;

  background-image: url(../images/frida-ficha/lineas1.png),
    url(../images/frida-ficha/lineas2.png);
  background-position: right 30%, left 75%;
  background-repeat: no-repeat, no-repeat;
  background-size: 10%, 10%;
}

.ficha-section-btn {
  min-width: 420px;
  max-width: 420px;
  color: #373a81;
  font-size: 22px;
  line-height: 22px;
  font-family: "GilroySemiBold";
  height: 70px;
  text-align: center;
  background: linear-gradient(
    0deg,
    rgba(229, 231, 235, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 6px 10px 10px rgba(0, 0, 0, 0.2); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 6px 10px 10px rgba(0, 0, 0, 0.2); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 6px 10px 10px rgba(0, 0, 0, 0.2);
}

.ficha-section-btn:hover {
  color: #373a81;
  text-decoration: none;
}

.ficha-section-btn img {
  padding-right: 30px;
}

.desktop {
  display: flex;
}

.mobile {
  display: none;
}

.s1 {
  position: relative;
  padding-top: 70px;
}

.s1 .bg-img {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  background-image: url(../images/gallery.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.s1 h1 {
}

.s1 h1 .p-1 {
  color: #fff;
  font-family: "GilroyMedium";
  font-size: 41px;
  padding: 0;
  margin: 0;
  line-height: 10px;
}

.s1 h1 .p-2 {
  color: #c3c36c;
  font-family: "GilroyExtraBold";
  font-size: 54px;
  padding: 0;
  margin: 0;
}

.s1 .s1-col1 {
  display: flex;
  justify-content: flex-end;
  padding-top: 170px;
}

.s1 .s1-col2 {
  display: flex;
  flex-direction: column;
}

.s2 {
  padding-top: 100px;
}

.s2 .s2-col1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.s2 .s2-col1 h3 {
  margin-bottom: 30px;
}

.s2 .s2-col2 {
  display: flex;
  justify-content: center;
}

.s2 .s2-col2 img {
  width: 80%;
}

.s3 {
  padding-top: 100px;
}

.s3 .s3-col1 {
  margin-top: 20px;
  position: relative;
}

.s3 .s3-col1 p {
  margin-bottom: 30px;
}

.s3 .s3-col2 {
  display: flex;
  justify-content: flex-end;
}

.s3 .s3-col2 img {
  width: 80%;
}

.s4 {
  padding-top: 80px;
}

.s4 .s4-col1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 0px;
}

.s4 .s4-col1 p {
  margin-bottom: 30px;
}

.s4 .s4-col2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.s4 .s4-col2 img {
  height: 320px;
  width: 268px;
}

.s5 {
  padding-top: 100px;
}

.s5 .s5-col1 {
  margin-top: 20px;
  position: relative;
}

.s5 .s5-col1 p {
  margin-bottom: 30px;
}

.s5 .s5-col2 {
  display: flex;
  justify-content: center;
}

.s5 .s5-col2 img {
  width: 260px;
}

.s6 {
  padding-top: 100px;
  text-align: center;
}

.s6 .s6-col1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.s6 .s6-col1 h3 {
  margin-bottom: 40px;
}

.s6 .s6-col1 h4 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.s6 .quote {
  font-style: italic;
  font-size: 16px;
  margin-top: 20px;
  font-family: "GilroyMedium";
}

.s7 {
  padding-top: 100px;
}

.s7 .s7-col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.s7 .s7-col1 img {
  width: 170px;
  margin-bottom: 16px;
}

.s7 .s7-col1 .s7-col1-content {
  display: inline-block;
}

.s7 .s7-col1 h5 {
  font-size: 20px;
  line-height: 20px;
}

.s7 .s7-col2 {
  display: flex;
  justify-content: center;
}

.s7 .s7-col2 img {
  width: 100%;
}

.s7 .s7-col2 video {
  width: 100%;
}

.ficha-btn-tienda {
  color: #fff;
  font-size: 29px;
  font-family: "GilroySemiBold";
  height: 100px;
  text-align: center;
  background: linear-gradient(
    0deg,
    rgba(171, 171, 93, 1) 0%,
    rgba(195, 195, 108, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  line-height: 32px;
  border: 3px solid #c3c36c;
  min-width: 420px;
  max-width: 420px;
  margin-bottom: 80px;
  margin-top: -100px;
}

.ficha-btn-tienda:hover {
  text-decoration: none;
  color: #fff;
}

.ficha-btn-gallery {
  min-width: 420px;
  color: #373a81;
  font-size: 29px;
  font-family: "GilroySemiBold";
  height: 100px;
  text-align: center;
  background: linear-gradient(
    0deg,
    rgba(229, 231, 235, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  line-height: 32px;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 6px 10px 10px rgba(0, 0, 0, 0.2); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 6px 10px 10px rgba(0, 0, 0, 0.2); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 6px 10px 10px rgba(0, 0, 0, 0.2);
}

.ficha-btn-gallery:hover {
  color: #373a81;
  text-decoration: none;
}

.ficha-btn-gallery img {
  padding-right: 30px;
}

.ficha-contactar-btn-tienda {
  color: #fff;
  font-size: 22px;
  line-height: 22px;
  font-family: "GilroySemiBold";
  height: 70px;
  text-align: center;
  background: linear-gradient(
    0deg,
    rgba(171, 171, 93, 1) 0%,
    rgba(195, 195, 108, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 3px solid #c3c36c;
  min-width: 420px;
  max-width: 420px;
}

.ficha-contactar-btn-tienda:hover {
  text-decoration: none;
  color: #fff;
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 992px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
  }
  .s1 .bg-img {
    left: 0;
    top: 209px;
    width: 100%;
    height: 300px;
  }
  .s1 .s1-col1 {
    padding-top: 0;
    justify-content: center;
    padding: 100px 0;
    margin-top: 47px;
  }
  .s1 h1 .p-1 {
    font-size: 37px;
  }
  .s1 h1 .p-2 {
    font-size: 48px;
  }
  .s1 .btn-tienda {
    font-size: 29px;
  }
  .ficha-btn-gallery {
    font-size: 24px;
  }
  .ficha-btn-tienda {
    font-size: 24px;
    margin-top: 0px;
  }
  .s1 .s1-col2 {
    align-items: center;
    padding-top: 80px;
  }
  .s2 .s2-col2 {
    margin-top: 60px;
  }
  .s3 {
    padding-top: 40px;
  }
  .s3 .s3-col2 {
    justify-content: center;
  }
  .s3 .s3-col3 {
    justify-content: center;
    margin-top: 30px;
  }
  .ficha-section-btn {
    min-width: 100%;
    max-width: 100%;
  }
  .s4 {
    padding-top: 60px;
  }
  .s4 .s4-col2 img {
    margin-bottom: 30px;
  }
  .s5 {
    padding-top: 40px;
  }
  .s5 .s5-col2 img {
    margin-bottom: 30px;
  }
  .s6 {
    padding-top: 60px;
  }
  .ficha-contactar-btn-tienda {
    min-width: 100%;
    max-width: 100%;
  }
  .s7 .s7-col1 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .ficha-btn-gallery {
    min-width: 100%;
  }
  .ficha-btn-tienda {
    max-width: 100%;
    min-width: 100%;
  }
  .s1 .s1-col1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .ficha-btn-gallery {
    min-width: 100%;
  }
  .ficha-btn-tienda {
    max-width: 100%;
    min-width: 100%;
  }
  .s1 {
    padding-top: 35px;
  }
  .s1 .s1-col1 {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: -8px;
  }
  .s1 .bg-img {
    top: 159px;
  }
}

@media only screen and (max-height: 310px) {
  .s1 {
    padding-top: 40px;
  }
  .s1 .bg-img {
    top: 169px;
  }
  .s1 .s1-col1 {
    margin-top: -3px;
  }
}
