.container-modal-botero {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.box-modal-botero {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  border: 2px solid #000;
  padding: 60px 40px 20px 40px;
  position: relative;
  border: 2px solid #fff;
  -webkit-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.box-modal-botero h2 {
  font-size: 20px;
  color: #373a81;
  text-align: center;
  margin-bottom: 40px;
  padding: 0 10px;
  font-family: "GilroyBold";
}

.box-modal-botero p {
  font-size: 18px;
  color: #373a81;
  text-align: center;
  margin-bottom: 40px;
  padding: 0 10px;
  font-family: "GilroyMedium";
  max-width: 400px;
}

.box-modal-botero p span {
  font-family: "GilroyBold";
}

@media only screen and (max-height: 390px) {
  .box-modal-botero h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .box-modal-botero p {
    font-size: 16px;
    margin-bottom: 20px;
    max-width: 350px;
  }
}
